<template>
  <div class="tabs">
    <div class="tabs__navigation" :class="'flex-'+controlsAlign">
      <slot name="tabs-navigation" v-bind="{ tabsComponent: this }">
        <div class="tabs__navigation-inner" :class="'flex-'+controlsAlign">
          <div
            v-for="tab of tabs"
            class="tabs__control"
            :class="{ active: tab.id === activeTab}"
            @click="showTab(tab.id)">
            <slot :name="`tab-control-${tab.id}`">
              <icon-text v-if="tab.icon"
                         :auto-translate="false"
                         :icon="tab.icon"
                         :text="getTranslatedTabLabel(tab)"></icon-text>
              <span v-else>
                 {{ getTranslatedTabLabel(tab) }}
              </span>
            </slot>
          </div>
        </div>
      </slot>
    </div>
    <div class="tabs-content">
      <transition-group
        mode="out-in"
        enter-active-class="animate__fadeIn animate--fast"
        leave-active-class="animate__fadeOut animate--fast">
        <div
          v-for="tab of tabs"
          v-show="tab.id === activeTab"
          class="tab animate__fadeIn animate--fast">
          <slot :name="`tab-${tab.id}`"></slot>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import TabsMixin from "@/client/extensions/mixins/baseComponents/tabs.js";
export default {
  mixins: [TabsMixin],
};
</script>

<style scoped lang="scss"></style>
