export default {
  props: {
    tabs: {
      type: Array,
      default: [],
    },
    controlsAlign: {
      type: String,
      default: "inline-start",
    },
  },
  emits: ["tabs:tab-activated", "tabs:tab-activated-internally"],
  data: function () {
    return {
      activeTab: this.tabs[0] ? this.tabs[0].id : false,
    };
  },
  watch: {
    activeTab(newVal) {
      this.$emit("tabs:tab-activated", {
        id: newVal,
        tabId: newVal,
        tab: this.getTabById(newVal),
      });
    },
  },
  methods: {
    getTabById(id) {
      let tabs = this.tabs.filter((tab) => tab.id === id);

      if (tabs.length < 1) {
        return false;
      }

      return tabs[0];
    },
    showTab(id) {
      if (!this.getTabById(id)) {
        warn("tabs component: attempt to switch to a tab that is not found", {
          targetId: id,
          tabs: this.tabs,
        });
      }
      this.activeTab = id;
      this.$emit("tabs:tab-activated", {
        id: id,
        tabId: id,
        tab: this.getTabById(id),
      });
    },
    getTranslatedTabLabel(tab) {
      if (!tab.label) {
        return "";
      }

      if (!this.autoTranslate) {
        return tab.label;
      }

      if (typeof tab.translate === "boolean" && !tab.translate) {
        return utilities.ucFirst(tab.label);
      }

      return this.translateUcFirst(tab.label);
    },
  },
};
